<script>
import Layout from "@/router/layouts/auth";
import { api } from "@/state/services";
import Languages from "@/components/widgets/languages";

export default {
  locales: {
    pt: {
      "Your new Tether wallet has been confirmed.":
        "Sua nova carteira de Tether foi confirmada.",
      "The token is invalid.": "O token é inválido.",
      "Sign In here": "Entrar",
    },
    es: {
      "Your new Tether wallet has been confirmed.":
        "Su nueva cartera Tether ha sido confirmada.",
      "The token is invalid.": "El token no es válido.",
      "Sign In here": "Identifícate aquí",
    },
  },
  components: {
    Layout,
    Languages,
  },
  data() {
    return {
      loading: true,
      success: false,
      token: null,
    };
  },
  methods: {
    getTether() {
      api.get("tether/confirm/" + this.token).then((response) => {
        if (response.data.status == "success") {
          this.success = true;
        } else {
          this.success = false;
        }

        this.loading = false;
      });
    },
  },
  mounted() {
    if (this.$route.query.token) {
      this.token = this.$route.query.token;
      this.getTether();
    }
  },
};
</script>

<template>
  <Layout>
    <div
      id="auth"
      class="row m-0 flex-column-reverse flex-sm-row align-items-center"
    >
      <div class="col">
        <div class="login-content">
          <div class="pt-5 text-center">
            <router-link class="align-middle" tag="a" to="/">
              <img
                class="w-100"
                style="max-width: 200px"
                src="@/assets/images/logo.png"
              />
            </router-link>
          </div>
          <div class="pt-5">
            <div v-if="loading" class="text-center">
              <b-spinner
                small
                class="align-top mt-2"
                variant="dark"
                role="status"
              ></b-spinner>
            </div>
            <div v-else>
              <div v-if="success" class="p-4 text-center">
                {{ t("Your new Tether wallet has been confirmed.") }}
              </div>
              <div v-else-if="token && !success" class="p-4 text-center">
                {{ t("The token is invalid.") }}
              </div>
            </div>
          </div>
          <div class="mt-4 text-center">
            <p class="text-white">
              <router-link
                tag="a"
                to="/login"
                class="font-weight-medium text-muted"
                >{{ t("Sign In here") }}</router-link
              >
            </p>
            <Languages type="dropup"></Languages>
          </div>
        </div>
      </div>
      <div class="col login-image d-none d-lg-block"></div>
    </div>
  </Layout>
</template>

<style lang="scss" module></style>